<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('loyalty_rules.loyalty_rules') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->
          <!--                    <router-link to="/loyalty/loyalty-rules" class="btn btn-light font-weight-bolder">-->
          <!--                        <v-icon>mdi-chevron-left</v-icon>-->
          <!--                        {{$t('back')}}-->
          <!--                    </router-link>-->
          <!--end::Button-->
        </div>
      </div>

      <div class="card-body ">
        <b-card-body class="p-0">
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('loyalty_rules.name') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
            </div>
            <div class="col-md-6 mb-5">
              <label>{{ $t('loyalty_rules.categories') }}<span class="text-danger">*</span></label>
              <div class="input-group">
                <treeselect
                    :options="categories"
                    :load-options="loadOptions"
                    :multiple="true"
                    :value="data.category_ids"
                    @input="updateValue"
                    :searchable="true"
                    :class="validation && validation.category_ids ? 'is-invalid' : ''"
                    :show-count="true"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :placeholder="$t('Select')">
                </treeselect>
                <div class="input-group-prepend">
                  <a class="btn btn-primary" href="/settings/categories" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                </div>
              </div>
              <span v-if="validation && validation.category_ids" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.category_ids[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('loyalty_rules.proiority') }}</label>
              <input type="number" v-model="data.proiority" min="0" class="form-control" :class="validation && validation.proiority ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.proiority" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.proiority[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('loyalty_rules.collection_factor') }}</label>
              <input type="number" v-model="data.collection_factor" min="0" class="form-control" :class="validation && validation.collection_factor ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.collection_factor" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.collection_factor[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('loyalty_rules.minimum_total_spent') }}</label>
              <input type="number" v-model="data.minimum_total_spent" min="0" class="form-control" :class="validation && validation.minimum_total_spent ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.minimum_total_spent" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.minimum_total_spent[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('loyalty_rules.currency') }}<span class="text-danger">*</span></label>
              <div class="input-group">
                <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <div class="input-group-prepend">
                  <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                </div>
              </div>

              <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('exchange_rate') }}</label>
              <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.exchange_rate[0] }}
                                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('loyalty_rules.expired_after') }}</label>
              <input type="number" v-model="data.expired" min="0" class="form-control" :class="validation && validation.expired ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.expired" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.expired[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('loyalty_rules.interval_type') }}</label>
              <select name="" id="interval_type" v-model="data.interval_type" class="custom-select" :class="validation && validation.interval_type ? 'is-invalid' : ''">
                <option v-for="row in interval_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.interval_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.interval_type[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.status" :class="validation && validation.status ? 'is-invalid' : ''" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
            </div>
          </div>

        </b-card-body>

        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "from-loyalty-rules",
  data() {
    return {
      mainRoute: 'loyaltyrules/loyalty-rules',
      mainRouteDependency: 'base/dependency',

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        name: null,
        category_ids: null,
        currency_id: null,
        collection_factor: null,
        minimum_total_spent: null,
        expired: null,
        proiority: null,
        interval_type: 0,
        status: false,
        exchange_rate: null,
      },
      isEditing: false,
      validation: null,

      categories: [],
      currencies: [],
      interval_type_list: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/loyalty/loyalty-rules');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/loyalty/loyalty-rules');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.category_ids = response.data.data.category_ids;
        this.data.currency_id = response.data.data.currency_id;
        this.data.collection_factor = response.data.data.collection_factor;
        this.data.minimum_total_spent = response.data.data.minimum_total_spent;
        this.data.expired = response.data.data.expired;
        this.data.proiority = response.data.data.proiority;
        this.data.interval_type = response.data.data.interval_type;
        this.data.status = response.data.data.status;
        this.data.exchange_rate = response.data.data.exchange_rate;
      });
    },
    updateValue(value) {
      this.data.category_ids = value
    },
    loadOptions() {
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getIntervalType() {
      ApiService.get(this.mainRouteDependency + "/interval_type").then((response) => {
        this.interval_type_list = response.data.data;
      });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.loyalty_rules"), route: '/loyalty/loyalty-rules'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);


    this.getCategories();
    this.getCurrencies();
    this.getIntervalType();

    if (this.idEdit) {
      this.getData();

    } else {
      this.defaultDataForUser();
    }

  },
};
</script>


